import React from 'react'
import { Link } from 'gatsby'
import { useLocalization } from 'gatsby-theme-i18n'

const LanLink = () => {
  const { locale } = useLocalization()

  const otherLanLink = {
    en: {
      to: process.env.GATSBY_DEFAULT_LOCALE === 'en' ? '/zh' : '/',
      text: '中文',
    },
    zh: {
      to: process.env.GATSBY_DEFAULT_LOCALE === 'zh' ? '/en' : '/',
      text: 'en',
    },
  }
  return (
    <Link className='lan-link' to={otherLanLink[locale].to}>
      <div className='inner'>
        <span>{otherLanLink[locale].text}</span>
      </div>
    </Link>
  )
}

export default LanLink
