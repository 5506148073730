import React from 'react'
import LinkWrap from 'components/LinkWrap'
import LogoStandardSvg from '../images/vi/logo-standard.svg'

export default function Header({
  addClass,
  menuDown,
  mobileMenuOpen,
  firstLoadLoaded,
}) {
  const menuDownClass = menuDown ? 'menu-down' : ''
  const mobileMenuOpenClass = mobileMenuOpen ? 'mobile-menu-open' : ''
  const firstLoadLoadedClass = firstLoadLoaded ? 'first-load-loaded' : ''
  return (
    <div
      className={`header ${addClass} ${menuDownClass} ${mobileMenuOpenClass} ${firstLoadLoadedClass}`}
    >
      <div className='inner'>
        <div className='logo'>
          <LinkWrap to='/'>
            <LogoStandardSvg />
          </LinkWrap>
        </div>
      </div>
    </div>
  )
}
