import React from 'react'
import { useInView } from 'react-intersection-observer'

const Component = ({ children, className, triggerOnce = false }) => {
  const [ref, inView] = useInView({
    triggerOnce,
  })
  return (
    <div
      className={`
    ${inView ? ' in-view' : ''}
    ${className ? className : ''}
    `}
      ref={ref}
    >
      {children}
    </div>
  )
}

export default Component
