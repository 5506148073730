import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import ImageWrapLocal from 'components/ImageWrapLocal'
import Social from 'components/Social'
import LinkWrap from 'components/LinkWrap'
import Inview from 'components/Inview'
import { useLocalization } from 'gatsby-theme-i18n'
import { tr } from 'content/strings'
import LogoTextInverseSvg from '../images/vi/logo-text-inverse.svg'

export default function Footer() {
  return (
    <StaticQuery query={menuQuery} render={(data) => <Inner data={data} />} />
  )
}
const Inner = ({ data }) => {
  const { locale } = useLocalization()

  const resolve = (input) => {
    const result = data[`${input}_${locale}`]?.title
    const output = result ? result : '[no title]'
    return output
  }

  const links = [
    {
      to: '/about/brand-story/',
      text: resolve('brandStory'),
    },
    {
      to: '/diamonds/',
      text: resolve('diamonds'),
    },
    {
      to: '/collections/',
      text: tr('collections', locale),
    },
    // {
    //   to: '/news/',
    //   text: resolve('news'),
    // },
    // {
    //   to: '/awards/',
    //   text: resolve('awards'),
    // },
    {
      to: '/contact/',
      text: resolve('contact'),
    },
  ]

  let copyright = {
    en: `<span>© Copyright Z Diamond.</span> <span>All rights reserved.</span> <span>Credit: <a href="https://lantern.digital" target="_blank">website design</a>.</span>`,
    zh: `<span>© Copyright Z Diamond. 版权所有</span> <span><a href="https://www.lanterndigital.com.cn" target="_blank">网站设计</a></span>`,
  }
  copyright = copyright[locale]
  if (process.env.GATSBY_SHOW_ICP === 'true') {
    copyright +=
      ' <span><a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备16006410号-2</a></span>'
    copyright +=
      '<span><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011802005050" target="_blank"><img class="gongan" src="/icon/gongan.jpg" alt="" />沪公网安备 31011802005050号</a></span>';
    copyright +=
      ' <br /><span><img src="/icon/address.png">：上海市青浦区华浦路500号</span> <span><img src="/icon/phone.png">：69720908</span>'
  }

  return (
    <footer className='text-color-inverse'>
      <div className='container'>
        <div className='logo'>
          <div className='image'>
            <LinkWrap to='/'>
              <LogoTextInverseSvg />
            </LinkWrap>
          </div>
        </div>
        <div className='links'>
          {links.map((item, i) => (
            <Link key={i} content={item} />
          ))}
        </div>
        <Social />
        <div className='copyright'>
          <p
            className='smallest'
            dangerouslySetInnerHTML={{ __html: copyright }}
          />
        </div>
      </div>
    </footer>
  )
}

const Link = ({ content }) => {
  const { to, text } = content
  return (
    <h4>
      <LinkWrap to={to}>
        <span>{text}</span>
      </LinkWrap>
    </h4>
  )
}

const menuQuery = graphql`
  query {
    awards_en: strapiAwardsPage(locale: { eq: "en" }) {
      title
    }
    awards_zh: strapiAwardsPage(locale: { eq: "zh" }) {
      title
    }
    brandStory_en: strapiBrandStoryPage(locale: { eq: "en" }) {
      title
    }
    brandStory_zh: strapiBrandStoryPage(locale: { eq: "zh" }) {
      title
    }
    collections_en: strapiCollectionsPage(locale: { eq: "en" }) {
      title
    }
    collections_zh: strapiCollectionsPage(locale: { eq: "zh" }) {
      title
    }
    contact_en: strapiContactPage(locale: { eq: "en" }) {
      title
    }
    contact_zh: strapiContactPage(locale: { eq: "zh" }) {
      title
    }
    diamonds_en: strapiDiamondsPage(locale: { eq: "en" }) {
      title
    }
    diamonds_zh: strapiDiamondsPage(locale: { eq: "zh" }) {
      title
    }
    diamondsSearch_en: strapiDiamondsSearchPage(locale: { eq: "en" }) {
      title
    }
    diamondsSearch_zh: strapiDiamondsSearchPage(locale: { eq: "zh" }) {
      title
    }
    home_en: strapiHomePage(locale: { eq: "en" }) {
      title
    }
    home_zh: strapiHomePage(locale: { eq: "zh" }) {
      title
    }
    missionAndFuture_en: strapiMissionAndFuturePage(locale: { eq: "en" }) {
      title
    }
    missionAndFuture_zh: strapiMissionAndFuturePage(locale: { eq: "zh" }) {
      title
    }
    news_en: strapiNewsPage(locale: { eq: "en" }) {
      title
    }
    news_zh: strapiNewsPage(locale: { eq: "zh" }) {
      title
    }
    timeline_en: strapiTimelinePage(locale: { eq: "en" }) {
      title
    }
    timeline_zh: strapiTimelinePage(locale: { eq: "zh" }) {
      title
    }
  }
`
