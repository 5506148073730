import React, { useState, useEffect } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import LinkWrap from 'components/LinkWrap'
import LanLink from 'components/LanLink'
import Inview from 'components/Inview'
import { useLocalization } from 'gatsby-theme-i18n'
import { tr } from 'content/strings'

export default function MobileMenu({
  mobileMenuOpen,
  toggleMobileMenuOpen,
  menuDown,
  path,
}) {
  let showClass = ''
  if (mobileMenuOpen) showClass = 'show'
  return (
    <>
      <div
        className={`side-in-overlay ${showClass}`}
        onClick={toggleMobileMenuOpen}
      />
      <StaticQuery
        query={menuQuery}
        render={(data) => (
          <Inner
            data={data}
            mobileMenuOpen={mobileMenuOpen}
            menuDown={menuDown}
            path={path}
          />
        )}
      />
    </>
  )
}

const Inner = ({ data, mobileMenuOpen, menuDown, path }) => {
  const [openM0Index, setOpenM0Index] = useState()
  const toggleM0Index = () => setOpenM0Index(!openM0Index)

  const { locale } = useLocalization()

  const resolve = (input) => {
    const result = data[`${input}_${locale}`]?.title
    const output = result ? result : '[no title]'
    return output
  }

  const collections = data[`allCollections_${locale}`].nodes

  const collectionsSubmenu = [
    // {
    //   to: '/collections',
    //   activePathMatch: '/collections',
    //   exactMatch: true,
    //   text: resolve('collections'),
    // },
  ]
  collections.forEach((collection) => {
    collectionsSubmenu.push({
      to: `/collections/${collection.slug}`,
      activePathMatch: `/collections/${collection.slug}`,
      text: collection.title,
    })
  })

  let menu = [
    {
      to: '/',
      text: resolve('home'),
    },
    {
      activePathMatch: '/about',
      text: tr('about', locale),
      submenu: [
        {
          to: '/about/brand-story',
          activePathMatch: '/about/brand-story',
          text: resolve('brandStory'),
        },
        {
          to: '/about/mission-future',
          activePathMatch: '/about/mission-future',
          text: resolve('missionAndFuture'),
        },
        {
          to: '/about/timeline',
          activePathMatch: '/about/timeline',
          text: resolve('timeline'),
        },
      ],
    },
    {
      activePathMatch: '/diamonds',
      text: tr('diamonds', locale),
      submenu: [
        {
          to: '/diamonds',
          activePathMatch: '/diamonds',
          text: resolve('diamonds'),
        },
        {
          to: '/diamonds/search',
          activePathMatch: '/diamonds/search',
          text: resolve('diamondsSearch'),
        },
      ],
    },
    {
      activePathMatch: '/collections',
      text: tr('collectionsTreasure', locale),
      submenu: collectionsSubmenu,
    },
    {
      activePathMatch: '/news',
      activePathMatch2: '/awards',
      text: tr('media', locale),
      submenu: [
        {
          to: '/news',
          activePathMatch: '/news',
          text: resolve('news'),
        },
        // {
        //   to: '/awards',
        //   activePathMatch: '/awards',
        //   text: resolve('awards'),
        // },
      ],
    },
    {
      to: '/contact',
      activePathMatch: '/contact',
      text: resolve('contact'),
    },
  ]

  return (
    <div
      className={`side-in ${mobileMenuOpen ? 'open' : ''}
    ${menuDown ? 'menu-down' : ''}
    `}
    >
      <Inview className='inner fade-in'>
        <ul className='menu'>
          {menu.map((item, i) => (
            <MenuLink
              key={i}
              i={i}
              content={item}
              path={path}
              mobileMenuOpen={mobileMenuOpen}
              openM0Index={openM0Index}
              setOpenM0Index={setOpenM0Index}
              toggleM0Index={toggleM0Index}
            />
          ))}
          <li
            className={`fade-in up stagger-700 
            ${mobileMenuOpen ? 'mobile-menu-open' : ''}
            `}
          >
            <LanLink />
          </li>
        </ul>
      </Inview>
    </div>
  )
}

const MenuLink = ({
  i,
  content,
  path,
  mobileMenuOpen,
  openM0Index,
  setOpenM0Index,
  toggleM0Index,
}) => {
  // const [isOpenM0, setOpenM0] = useState()
  // const toggleOpenM0 = () => setOpenM0(!isOpenM0)

  useEffect(() => {
    if (path.includes(activePathMatch)) {
      // setOpenM0(true)
      setOpenM0Index(i)
    }
  }, [])

  const { to, activePathMatch, activePathMatch2, text, submenu } = content
  let activeClass = '',
    openM0Class = '',
    hasSubmenuClass = '',
    mobileMenuOpenClass = ''

  path = path.replace('/en', '')

  if (path.includes(activePathMatch) || path.includes(activePathMatch2)) {
    activeClass = 'active'
  }

  // if (isOpenM0) {
  //   openM0Class = 'open-m0'
  // }
  if (openM0Index === i) {
    openM0Class = 'open-m0'
  }
  if (submenu) {
    hasSubmenuClass = 'has-submenu'
  }
  let noDelayClass = 'no-delay'
  if (mobileMenuOpen) {
    mobileMenuOpenClass = 'mobile-menu-open'
    noDelayClass = 'no-delay'
  }

  const handleClick = (i) => {
    if (openM0Index === i) {
      toggleM0Index(i)
    } else {
      setOpenM0Index(i)
    }
  }

  return (
    <li
      className={`
      fade-in up  stagger-${i * 100 + 200} 
      ${openM0Class} ${hasSubmenuClass} ${activeClass} ${mobileMenuOpenClass} ${noDelayClass}`}
    >
      <LinkWrap to={to}>
        <div
          className='inner'
          // onClick={() => toggleOpenM0()}
          onClick={() => handleClick(i)}
        >
          <span className='trigger' />
          {text}
        </div>
      </LinkWrap>
      {submenu && (
        <ul className='submenu'>
          {submenu.map((subitem, j) => (
            <SubmenuLink
              key={j}
              j={j}
              content={subitem}
              path={path}
              openM0Class={openM0Class}
            />
          ))}
        </ul>
      )}
    </li>
  )
}

const SubmenuLink = ({ j, content, path, openM0Class }) => {
  const { to, activePathMatch, exactMatch, text } = content

  let activeClass = ''
  if (exactMatch && path === activePathMatch) {
    activeClass = 'active'
  }
  if (!exactMatch && path.includes(activePathMatch)) {
    activeClass = 'active'
  }
  if (path === '/diamonds/search' && activePathMatch === '/diamonds') {
    activeClass = ''
  }

  return (
    <li className={`fade-in up stagger-${j * 100 + 0} ${openM0Class}`}>
      <h2>
        <LinkWrap to={to} className={activeClass}>
          {text}
        </LinkWrap>
      </h2>
    </li>
  )
}

const menuQuery = graphql`
  query {
    allCollections_en: allStrapiCollection(
      sort: { fields: order }
      filter: { locale: { eq: "en" } }
    ) {
      nodes {
        slug
        title
      }
    }
    allCollections_zh: allStrapiCollection(
      sort: { fields: order }
      filter: { locale: { eq: "zh" } }
    ) {
      nodes {
        slug
        title
      }
    }
    awards_en: strapiAwardsPage(locale: { eq: "en" }) {
      title
    }
    awards_zh: strapiAwardsPage(locale: { eq: "zh" }) {
      title
    }
    brandStory_en: strapiBrandStoryPage(locale: { eq: "en" }) {
      title
    }
    brandStory_zh: strapiBrandStoryPage(locale: { eq: "zh" }) {
      title
    }
    collections_en: strapiCollectionsPage(locale: { eq: "en" }) {
      title
    }
    collections_zh: strapiCollectionsPage(locale: { eq: "zh" }) {
      title
    }
    contact_en: strapiContactPage(locale: { eq: "en" }) {
      title
    }
    contact_zh: strapiContactPage(locale: { eq: "zh" }) {
      title
    }
    diamonds_en: strapiDiamondsPage(locale: { eq: "en" }) {
      title
    }
    diamonds_zh: strapiDiamondsPage(locale: { eq: "zh" }) {
      title
    }
    diamondsSearch_en: strapiDiamondsSearchPage(locale: { eq: "en" }) {
      title
    }
    diamondsSearch_zh: strapiDiamondsSearchPage(locale: { eq: "zh" }) {
      title
    }
    home_en: strapiHomePage(locale: { eq: "en" }) {
      title
    }
    home_zh: strapiHomePage(locale: { eq: "zh" }) {
      title
    }
    missionAndFuture_en: strapiMissionAndFuturePage(locale: { eq: "en" }) {
      title
    }
    missionAndFuture_zh: strapiMissionAndFuturePage(locale: { eq: "zh" }) {
      title
    }
    news_en: strapiNewsPage(locale: { eq: "en" }) {
      title
    }
    news_zh: strapiNewsPage(locale: { eq: "zh" }) {
      title
    }
    timeline_en: strapiTimelinePage(locale: { eq: "en" }) {
      title
    }
    timeline_zh: strapiTimelinePage(locale: { eq: "zh" }) {
      title
    }
  }
`
