import React from 'react'
import LinkWrap from 'components/LinkWrap'
import ImageWrapLocal from 'components/ImageWrapLocal'

export default function SearchLink({
  addClass = '',
  mobileMenuOpen,
  firstLoadLoaded,
  menuDown,
}) {
  const menuDownClass = menuDown ? 'menu-down' : ''
  const mobileMenuOpenClass = mobileMenuOpen ? 'mobile-menu-open' : ''
  const firstLoadLoadedClass = firstLoadLoaded ? 'first-load-loaded' : ''
  return (
    <LinkWrap
      className={`search-link ${addClass} ${firstLoadLoadedClass} ${mobileMenuOpenClass} ${menuDownClass}`}
      to='/search/'
    >
      <div className='image'>
        <div className='inverse'>
          <ImageWrapLocal filename='search-white.png' />
        </div>
        <div className='standard'>
          <ImageWrapLocal filename='search.png' />
        </div>
      </div>
    </LinkWrap>
  )
}
