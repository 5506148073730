import React, { useState, useEffect } from 'react'
import Header from 'components/Header'
import MenuThumb from 'components/MenuThumb'
import SearchLink from 'components/SearchLink'
import MobileMenu from 'components/MobileMenu'
import { globalHistory } from '@reach/router'
import { useLocalization } from 'gatsby-theme-i18n'
import Seo from 'components/Seo'
import Footer from 'components/Footer'
import 'swiper/swiper.scss'

const Layout = (props) => {
  const { children, path, data } = props
  const { locale } = useLocalization()
  const [menuDown, setMenuDown] = useState(false)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [headerClass, setHeaderClass] = useState('')
  const [firstLoadLoaded, setFirstLoadLoaded] = useState(false)
  const toggleMobileMenuOpen = () => setMobileMenuOpen(!mobileMenuOpen)

  const extractBannerVersion = (data) => {
    for (const key in data) {
      const dataType = data[key]
      for (const dataTypeKey in dataType) {
        if (dataTypeKey === 'introBannerBlock') {
          const textColor = dataType.introBannerBlock?.textColor
          return textColor
        }
      }
    }
  }

  const extractSeoData = (data) => {
    let seoData = {
      pageTitle: null,
      seo: {
        title: null,
        description: null,
      },
    }
    for (const key in data) {
      const dataType = data[key]
      for (const dataTypeKey in dataType) {
        if (dataTypeKey === 'title') {
          seoData.pageTitle = dataType.title
        }
        if (dataTypeKey === 'seo') {
          seoData.seo.title = dataType.seo?.title
          seoData.seo.description = dataType.seo?.description
        }
      }
    }
    return seoData
  }

  const updateHeaderClass = (data) => {
    setTimeout(() => {
      let headerClass = 'default'
      const result = extractBannerVersion(data)
      if (result) {
        headerClass = 'transparent-' + result
      }
      if (data.strapiTimelinePage) {
        headerClass = 'default-white'
      }
      if (data.strapiSearchPage) {
        headerClass = 'default-transparent'
      }
      if (data.strapiAwardsPage && !data.strapiAwardsPost) {
        // awards list page
        headerClass = 'default'
        const getBlock = data.allStrapiAwardsPost.nodes[0].bannerImageOnlyBlock
        if (getBlock) {
          headerClass = 'transparent-' + getBlock.textColor
        }
      }
      setHeaderClass(headerClass)
    }, 1)
  }

  let seoData
  if (data) {
    updateHeaderClass(data)
    seoData = extractSeoData(data)
  }

  useEffect(() => {
    setTimeout(() => setFirstLoadLoaded(true), 1000)
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        setTimeout(() => setMenuDown(false), 400)
        setTimeout(() => setMobileMenuOpen(false), 1)
      }
    })
  }, [setMobileMenuOpen])

  const smartMenu = () => {
    if (window.scrollY > 400) {
      setMenuDown(true)
    } else {
      setMenuDown(false)
    }
  }
  if (typeof window !== 'undefined') {
    window.addEventListener('scroll', smartMenu)
  }

  return (
    <div id='layout' className={`locale-${locale}`}>
      <Seo seoData={seoData} />
      <Header
        addClass={`top ${headerClass}`}
        firstLoadLoaded={firstLoadLoaded}
      />
      <Header
        mobileMenuOpen={mobileMenuOpen}
        menuDown={menuDown}
        addClass='scrolldown'
        firstLoadLoaded={firstLoadLoaded}
      />

      <MenuThumb
        toggleMobileMenuOpen={toggleMobileMenuOpen}
        mobileMenuOpen={mobileMenuOpen}
        addClass={`top ${headerClass}`}
        firstLoadLoaded={firstLoadLoaded}
      />
      <MenuThumb
        toggleMobileMenuOpen={toggleMobileMenuOpen}
        mobileMenuOpen={mobileMenuOpen}
        menuDown={menuDown}
        addClass='scrolldown'
      />

      <SearchLink
        addClass={`top ${headerClass}`}
        mobileMenuOpen={mobileMenuOpen}
        firstLoadLoaded={firstLoadLoaded}
      />
      <SearchLink menuDown={menuDown} addClass='scrolldown' />

      <MobileMenu
        mobileMenuOpen={mobileMenuOpen}
        toggleMobileMenuOpen={toggleMobileMenuOpen}
        menuDown={menuDown}
        path={path}
      />
      <main>
        {children}
        <Footer />
      </main>
    </div>
  )
}

export default Layout
