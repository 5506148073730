const strings = {
  en: {
    about: 'About',
    awards: 'Awards',
    all: 'All',
    archive: 'Archive',
    category: 'Category',
    closeFilter: 'Close Filter',
    collection: 'Collection',
    collections: 'Collections',
    collectionsTreasure: 'Collections',
    companyName: 'Z DIAMOND Official',
    currencySign: '$',
    diamonds: 'Diamonds',
    diamondsAndCustomization: 'Diamonds and Customization',
    discover: 'Discover',
    filter: 'Filter',
    formNameLast: 'Last Name',
    formNameFirst: 'First Name',
    formPhone: 'Phone',
    formEmail: 'Email',
    formCompany: 'Company',
    formPosition: 'Position',
    formMessage: 'Message',
    formSubmit: 'Submit',
    formConfirm: 'Thanks for your message.',
    jewelry: 'Jewelry',
    keyword: 'Keyword',
    material: 'Material',
    media: 'Media',
    more: 'More',
    moreAwards: 'More Awards',
    moreDiamonds: 'More Diamonds',
    moreProducts: 'More Products',
    moreNewsPress: 'More News & Press',
    news: 'News',
    newsCategory: 'Category',
    productCategory: 'Category',
    productMaterial: 'Material',
    products: 'Products',
    relatedCollection: 'Related Collection',
    relatedDiamond: 'Related Diamond',
    relatedProduct: 'Related Product',
    relatedProducts: 'Related Products',
    results: 'Results',
    resultsFavorite: 'Favourite',
    section: 'Section',
    space: ' ',
    viewCollection: 'View Collection',
    viewProduct: 'View Product',
    diamondCarat: 'Carat',
    diamondCert: 'Certificate',
    diamondColor: 'Color',
    diamondClarity: 'Clarity',
    diamondCut: 'Cut',
    diamondPrice: 'Price',
    diamondPriceLocal: 'Price ($)',
    diamondCut_good: 'Good',
    diamondCut_very_good: 'Very Good',
    diamondCut_excellent: 'Excellent',
    diamondCut_ideal: 'Ideal',
    diamondShape: 'Shape',
    diamondShape_asscher: 'Asscher',
    diamondShape_round: 'Round',
    diamondShape_cushion: 'Cushion',
    diamondShape_emerald: 'Emerald',
    diamondShape_heart: 'Heart',
    diamondShape_marquise: 'Marquise',
    diamondShape_oval: 'Oval',
    diamondShape_pear: 'Pear',
    diamondShape_princess: 'Princess',
    diamondShape_radiant: 'Radiant',
  },
  zh: {
    about: '关于',
    all: '全部',
    archive: '存档',
    awards: '奖项',
    closeFilter: '关闭',
    collection: '成品系列',
    collections: '成品系列',
    collectionsTreasure: '珍藏瑰宝',
    companyName: '【Z DIAMOND卓缔梦官网】',
    currencySign: '¥',
    diamonds: '裸钻',
    diamondsAndCustomization: '裸钻与定制',
    discover: '探索更多',
    filter: '筛选',
    formNameLast: '姓',
    formNameFirst: '名',
    formPhone: '电话',
    formEmail: '邮箱',
    formCompany: '所属公司',
    formPosition: '职位',
    formMessage: '留言',
    formSubmit: '提交',
    formConfirm: '感谢您的留言。',
    jewelry: '珠宝',
    keyword: '关键字',
    media: '媒体',
    more: '更多',
    moreAwards: '更多奖项',
    moreDiamonds: '更多裸钻',
    moreProducts: '更多产品',
    moreNewsPress: '更多新闻',
    news: '新闻',
    newsCategory: '新闻类别',
    productCategory: '分类',
    productMaterial: '材质',
    products: '产品',
    relatedCollection: '相关系列',
    relatedDiamond: '相关裸钻',
    relatedProduct: '相关产品',
    relatedProducts: '相关产品',
    results: '结果',
    resultsFavorite: '心动之选',
    section: '内容类别',
    space: '',
    viewCollection: '探索系列',
    viewProduct: '探索系列产品',
    diamondCarat: '克拉',
    diamondCert: '证书号',
    diamondColor: '颜色',
    diamondClarity: '净度',
    diamondCut: '切工',
    diamondPrice: '价格',
    diamondPriceLocal: '价格 (¥)',
    diamondCut_good: '好',
    diamondCut_very_good: '很好',
    diamondCut_excellent: '非常好',
    diamondCut_ideal: '理想',
    diamondShape: '形状',
    diamondShape_asscher: '阿斯切',
    diamondShape_round: '圆形',
    diamondShape_cushion: '垫形',
    diamondShape_emerald: '祖母绿',
    diamondShape_heart: '心形',
    diamondShape_marquise: '橄榄形',
    diamondShape_oval: '椭圆形',
    diamondShape_pear: '水滴形',
    diamondShape_princess: '公主方',
    diamondShape_radiant: '雷迪恩',
  },
}

export const tr = (key, locale) => {
  const result = strings[locale][key]
  const output = result || result === '' ? result : '[translation not found]'
  return output
}
