// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-brand-story-js": () => import("./../../../src/pages/about/brand-story.js" /* webpackChunkName: "component---src-pages-about-brand-story-js" */),
  "component---src-pages-about-mission-future-js": () => import("./../../../src/pages/about/mission-future.js" /* webpackChunkName: "component---src-pages-about-mission-future-js" */),
  "component---src-pages-about-timeline-js": () => import("./../../../src/pages/about/timeline.js" /* webpackChunkName: "component---src-pages-about-timeline-js" */),
  "component---src-pages-awards-js": () => import("./../../../src/pages/awards.js" /* webpackChunkName: "component---src-pages-awards-js" */),
  "component---src-pages-collections-js": () => import("./../../../src/pages/collections.js" /* webpackChunkName: "component---src-pages-collections-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-diamonds-js": () => import("./../../../src/pages/diamonds.js" /* webpackChunkName: "component---src-pages-diamonds-js" */),
  "component---src-pages-diamonds-search-js": () => import("./../../../src/pages/diamonds/search.js" /* webpackChunkName: "component---src-pages-diamonds-search-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-awards-post-js": () => import("./../../../src/templates/awards-post.js" /* webpackChunkName: "component---src-templates-awards-post-js" */),
  "component---src-templates-collection-js": () => import("./../../../src/templates/collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-diamond-js": () => import("./../../../src/templates/diamond.js" /* webpackChunkName: "component---src-templates-diamond-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

