import 'styles/styles.scss'
import 'swiper/swiper.scss'
import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill()

// const blurTest = window.getComputedStyle(document.body).backdropFilter
// if (blurTest === undefined) {
//   // alert('not supported')
//   document.querySelector('body').classList.add('browser-no-filter')
// } else {
//   // alert('supported')
// }
