import React from 'react'
import ImageWrapLocal from 'components/ImageWrapLocal'

const Social = () => {
  return (
    <div className='social'>
      <div className='items'>
        <a className='item' target='_blank' href='https://zdiamond.tmall.com'>
          <ImageWrapLocal className='icon' filename='icon-tmall.png' />
        </a>
        <div className='item'>
          <ImageWrapLocal className='icon' filename='icon-wechat.png' />
          <ImageWrapLocal className='qr' filename='qr-wechat.jpg' />
        </div>
        <a
          className='item'
          target='_blank'
          href='https://v.douyin.com/2yYBgmC/'
        >
          <ImageWrapLocal className='icon' filename='icon-douyin.png' />
        </a>
        <a
          className='item'
          target='_blank'
          href='https://www.xiaohongshu.com/user/profile/596f64ab6a6a6938bdc7996c?xhsshare=CopyLink&appuid=596f64ab6a6a6938bdc7996c&apptime=1655271752'
        >
          <ImageWrapLocal className='icon' filename='icon-xiaohongshu.png' />
        </a>
        <a
          className='item'
          target='_blank'
          href='https://www.instagram.com/zdiamondofficial'
        >
          <ImageWrapLocal className='icon' filename='icon-instagram.png' />
        </a>
        <a
          className='item'
          target='_blank'
          href='https://www.facebook.com/103605821859607'
        >
          <ImageWrapLocal className='icon' filename='icon-facebook.png' />
        </a>
      </div>
    </div>
  )
}

export default Social
