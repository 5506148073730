import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocalization } from 'gatsby-theme-i18n'
import { tr } from 'content/strings'

export default function Seo({ seoData }) {
  if (!seoData) return false
  const { locale } = useLocalization()

  let title = seoData.pageTitle // default
  if (seoData.seo?.title) title = seoData.seo?.title // cms override
  const companyName = tr('companyName', locale) + tr('space', locale)
  const setTitle = `${companyName}- ${title}`

  return (
    <Helmet>
      <title>{setTitle}</title>
      {seoData.seo.description && (
        <meta name='description' content={seoData.seo.description} />
      )}
      {/* {keywords && <meta name='keywords' content={keywords} />} */}
    </Helmet>
  )
}
