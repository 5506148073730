import React from 'react'
import Inview from 'components/Inview'

export default function MenuThumb({
  toggleMobileMenuOpen,
  mobileMenuOpen,
  menuDown,
  firstLoadLoaded,
  addClass = '',
}) {
  const menuDownClass = menuDown ? 'menu-down' : ''
  const mobileMenuOpenClass = mobileMenuOpen ? 'mobile-menu-open' : ''
  const firstLoadLoadedClass = firstLoadLoaded ? 'first-load-loaded' : ''
  return (
    <div
      className={`menu-thumb ${mobileMenuOpenClass} ${menuDownClass} ${addClass} ${firstLoadLoadedClass}`}
      onClick={toggleMobileMenuOpen}
      onKeyDown={toggleMobileMenuOpen}
      role='button'
      tabIndex='0'
    >
      <Inview className='inner fade-in'>
        <div className='one'></div>
        <div className='two'></div>
        <div className='three'></div>
      </Inview>
    </div>
  )
}
